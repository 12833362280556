<template>
  <div class="home">
    <!-- 顶部 -->
    <div class="top">
      <div class="flex-between top-nav">
        <img class="logo" alt="logo" src="../assets/logo.png" />
        <div>
          <div class="language">
            <span
              :class="$i18n.locale == 'zh' ? 'cur' : ''"
              @click="anvChange('zh')"
              >中文</span
            >
            |
            <span
              :class="$i18n.locale == 'en' ? 'cur' : ''"
              @click="anvChange('en')"
              >EN</span
            >
            |
            <span
              :class="$i18n.locale == 'arab' ? 'cur' : ''"
              @click="anvChange('arab')"
              >عربي</span
            >
          </div>
          <nav id="nav">
            <ul>
              <li id="nav2" :class="activeIndex == 1 ? 'cur' : ''">
                <div class="go" @click="activeIndex = 1">{{ $t("nav1") }}</div>
              </li>
              <li
                id="nav17"
                :class="String(activeIndex).includes('2') ? 'cur' : ''"
              >
                <div class="go">{{ $t("nav2") }}</div>
                <div class="drop">
                  <dl class="dl2">
                    <dd>
                      <div class="go" @click="activeIndex = 21">
                        {{ $t("nav2-1") }}
                      </div>
                    </dd>
                    <dd>
                      <div
                        class="go"
                        style="margin-top: 10px"
                        @click="activeIndex = 22"
                      >
                        {{ $t("nav2-2") }}
                      </div>
                    </dd>
                    <dd>
                      <div
                        class="go"
                        style="margin-top: 10px"
                        @click="activeIndex = 23"
                      >
                        {{ $t("nav2-3") }}
                      </div>
                    </dd>
                  </dl>
                </div>
              </li>
              <li id="nav50" :class="activeIndex == 3 ? 'cur' : ''">
                <div class="go" @click="activeIndex = 3">{{ $t("nav3") }}</div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="top-mian" v-if="activeIndex == 1">
      <el-carousel indicator-position="outside" :interval="3000">
        <el-carousel-item v-for="item in images" :key="item">
          <img class="main-img" :src="item" alt="" style="object-fit: cover" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 矿产图片 -->
    <el-image
      v-if="activeIndex == 22"
      :src="imagesMinerals"
      style="width: 100%; height: 520px; filter: blur(1px)"
    ></el-image>
    <!-- 电子陶瓷级钛白粉 -->
    <el-image
      v-if="activeIndex == 23"
      :src="imagesMinerals"
      style="width: 100%; height: 520px; filter: blur(1px)"
    ></el-image>
    <!-- 联系我们图片 -->
    <el-image
      v-if="activeIndex == 3"
      :src="imagesCall"
      style="width: 100%; height: 520px; filter: blur(1px)"
    ></el-image>
    <!-- 中间 -->
    <div class="main w80">
      <!-- 首页开始 -->
      <template v-if="activeIndex == 1">
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav1-title-1") }}</span>
        </div>
        <div class="main-content">
          <p>
            {{ $t("nav1-content-p1") }}
          </p>
          <p>
            {{ $t("nav1-content-p2") }}
          </p>
          <p>
            {{ $t("nav1-content-p3") }}
          </p>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav1-title-2") }}</span>
        </div>
        <div class="main-content">
          <p>
            {{ $t("nav1-content-p4") }}
          </p>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav1-title-3") }}</span>
        </div>
        <div class="main-content" style="margin-bottom: 50px">
          <p>
            {{ $t("nav1-content-p5") }}
          </p>
        </div>
      </template>
      <!-- 首页结束 -->
      <!-- 联系我们开始 -->
      <template v-else-if="activeIndex == 3">
        <div class="lxwm">
          <p style="letter-spacing: 3px">{{ $t("nav3-title") }}</p>
          <p class="lxwm-t">
            {{ $t("nav3-title2") }}
          </p>
        </div>
        <div class="main-content lxwm-p">
          <el-row :gutter="24" style="margin-bottom: 24px">
            <el-col :span="24">
              <el-card class="box-card">
                <p class="card-title">{{ $t("nav3-content-title-1") }}</p>
                <p>
                  <i class="el-icon-location-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title3") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-address-1")
                  }}</span>
                </p>
                <p>
                  <i class="el-icon-phone-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title4") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-phone-1")
                  }}</span>
                </p>
                <p>
                  <i class="el-icon-message lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title5") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-Email-1")
                  }}</span>
                </p>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-bottom: 24px">
            <el-col :span="24">
              <el-card class="box-card">
                <p class="card-title">{{ $t("nav3-content-title-2") }}</p>
                <p>
                  <i class="el-icon-location-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title3") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-address-2")
                  }}</span>
                </p>
                <p>
                  <i class="el-icon-phone-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title4") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-phone-2")
                  }}</span>
                </p>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-bottom: 24px">
            <el-col :span="24">
              <el-card class="box-card">
                <p class="card-title">
                  Hong Kong Hua Tai Chemical Limited（香港华钛化工有限公司）
                </p>
                <p>
                  <i class="el-icon-location-outline lxwm-i"></i
                  ><span class="lxwm-s">Address：</span
                  ><span class="lxwm-i-p"
                    >A27 2/F HING YIP CENTRE, 31 HING YIP STREET KWUN TONG
                    KOWLOON HK</span
                  >
                </p>
                <p>
                  <i class="el-icon-message lxwm-i"></i
                  ><span class="lxwm-s">Email：</span
                  ><span class="lxwm-i-p">3219552358@qq.com</span>
                </p>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </template>
      <!-- 联系我们结束 -->
      <!-- 产品开始 -->
      <template v-else-if="activeIndex == 2 || activeIndex == 21">
        <div
          class="main-content-title"
          style="margin-bottom: 30px; margin-top: 100px"
        >
          <span class="main-content-title-one">{{ $t("nav2") }}</span>
          <span
            :class="
              $i18n.locale == 'zh'
                ? 'main-content-title-two'
                : 'main-content-title-two-en'
            "
            >{{ $t("nav2-1-title") }}</span
          >
        </div>
        <el-row :gutter="20">
          <el-col :span="8" v-for="(o, index) in productList" :key="o.name">
            <el-card
              :body-style="{ padding: '0px' }"
              style="margin-bottom: 20px"
            >
              <img :src="o.img" class="image" @click="dialogInfo(o, index)" />
              <div style="padding: 14px">
                <span class="card-name">{{ o.name }}</span>
                <div class="describe" v-if="o.type == '1'">
                  <span style="color: #29b0d7; font-weight: 700"
                    >{{ $t("nav2-1-title2") }}：</span
                  >
                  {{ o.describe2 }}
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </template>
      <template v-else-if="activeIndex == 22">
        <div class="kczy-title">
          <p>{{ $t("nav2-2-title") }}</p>
          <p>{{ $t("nav2-2-title2") }}</p>
        </div>
        <el-row :gutter="20" style="margin-top: 28px">
          <el-col :span="8" v-for="(o, index) in kczyList" :key="o.name">
            <el-card
              :body-style="{ padding: '0px' }"
              style="margin-bottom: 20px"
            >
              <img :src="o.img" class="image" />
              <div
                style="
                  padding: 14px;
                  text-align: center;
                  font-size: 20px;
                  letter-spacing: 3px;
                "
              >
                <span class="card-name">{{ o.name }}</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </template>
      <template v-else-if="activeIndex == 23">
        <div class="kczy-title">
          <p>{{ $t("nav2-3-title-p1") }}</p>
          <p>{{ $t("nav2-3-title-p2") }}</p>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav2-3-title1") }}</span>
        </div>
        <div class="main-content">
          <p>
            {{ $t("nav2-3-define") }}
          </p>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav2-3-title2") }}</span>
        </div>
        <div class="main-content">
          <p>
            {{ $t("nav2-3-introduction") }}
          </p>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav2-3-title3") }}</span>
        </div>
        <div class="main-content" style="margin-bottom: 50px">
          <p>
            {{ $t("nav2-3-nature") }}
          </p>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one"
            >{{ $t("nav2-3-title4") }}：HTR-223</span
          >
        </div>
        <div style="width: 80%; margin: 0 auto">
          <h3 style="text-align: left; margin: 20px 0">
            {{ $t("nav2-3-title5") }}
          </h3>
          <div>
            <el-table
              class="table"
              :data="tableData2"
              style="width: 100%"
              stripe
            >
              <el-table-column prop="name" :label="$t('drawer-table-title')">
                <template slot-scope="scope">
                  <div class="flex-between">
                    <div>{{ scope.row.name }}</div>
                    <div style="margin-right: 10px">{{ scope.row.type }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                width="200px"
                prop="value"
                :label="$t('nav2-3-title6')"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav2-3-rule") }}</span>
        </div>
        <div class="main-content" style="margin-bottom: 50px">
          <p>
            {{ $t("nav2-3-rule-1-cpntent") }}
          </p>
          <p>
            {{ $t("nav2-3-rule-2-cpntent") }}
          </p>
          <p>
            {{ $t("nav2-3-rule-3-cpntent") }}
          </p>
          <div style="margin-left: 95px">
            <p>
              {{ $t("nav2-3-rule-3-cpntent-1") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-3-cpntent-2") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-3-cpntent-3") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-3-cpntent-4") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-3-cpntent-5") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-3-cpntent-6") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-3-cpntent-7") }}
            </p>
          </div>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav2-3-rule-4") }}</span>
        </div>
        <div class="main-content" style="margin-bottom: 50px">
          <p>
            {{ $t("nav2-3-rule-4-cpntent-1") }}
          </p>
          <p>
            {{ $t("nav2-3-rule-4-cpntent-2") }}
          </p>
        </div>
        <div class="main-content-title">
          <span class="main-content-title-one">{{ $t("nav2-3-rule-5") }}</span>
        </div>
        <div class="main-content" style="margin-bottom: 50px">
          <p>
            {{ $t("nav2-3-rule-5-1-content") }}
          </p>
          <p>
            {{ $t("nav2-3-rule-5-2-content") }}
          </p>
          <p>
            {{ $t("nav2-3-rule-5-3-content") }}
          </p>
          <p>
            {{ $t("nav2-3-rule-5-4-content") }}
          </p>
        </div>
      </template>
      <!-- 产品结束 -->
    </div>
    <div style="height: 70px"></div>
    <!-- 底部 -->
    <div class="bottom">
      <div class="w80">
        <p>{{ $t("copyright") }}</p>
      </div>
    </div>
    <!-- 置顶 -->
    <div
      class="goTop"
      :class="$i18n.locale == 'arab' ? 'arabGoTop' : ''"
      v-show="top > 60"
      @click="backTop"
    >
      <i class="el-icon-caret-top" style="color: #29b0d7"></i>
    </div>
    <!-- 产品详情弹窗 -->
    <el-drawer
      :title="dTitle"
      :visible.sync="dialogVisible"
      direction="rtl"
      size="100%"
    >
      <div class="drawer-content">
        <div class="dialog-content">
          <p style="color: #29b0d7; font-weight: 700">
            {{ $t("nav2-1-title2") }}：
          </p>
          <p>{{ dContent.describe2 }}</p>
        </div>
        <div class="dialog-content">
          <p style="color: #29b0d7; font-weight: 700">
            {{ $t("drawer-title") }}：
          </p>
          <p>{{ dContent.describe1 }}</p>
        </div>
        <div v-if="tableData && tableData.length > 0">
          <!-- 表格 -->
          <el-table class="table" :data="tableData" style="width: 100%" stripe>
            <el-table-column prop="name" :label="$t('drawer-table-title')">
              <template slot-scope="scope">
                <div class="flex-between">
                  <div>{{ scope.row.name }}</div>
                  <div style="margin-right: 10px">{{ scope.row.type }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="200px"
              prop="value"
              :label="$t('drawer-table-title2')"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "pc",
  props: {
    msg: String,
  },
  data() {
    return {
      activeIndex: "1",
      lange: "zh",
      images: [
		require("../assets/img/banner-00.jpg"),
		require("../assets/img/banner-01.jpg"),
		require("../assets/img/banner-02.jpg"),
        require("../assets/img/banner-1.jpg"),
        require("../assets/img/banner-2.jpg"),
        // require("../assets/img/banner-3.jpg"),
        // require("../assets/img/banner-4.jpg"),
        // require("../assets/img/banner5.jpg"),
      ],
      imagesCall: require("../assets/img/call.jpg"),
      imagesMinerals: require("../assets/img/minerals.jpg"),
      btnFlag: false,
      top: 0,
      dialogVisible: false,
      dTitle: null,
      dContent: {},
      tableData: [],
    };
  },
  computed: {
    tableName() {
      return {
        name1: this.$t("nav2-1-table-1-name"),
        name2: this.$t("nav2-1-table-2-name"),
        name3: this.$t("nav2-1-table-12-name"),
        name4: this.$t("nav2-1-table-3-name"),
        name5: this.$t("nav2-1-table-4-name"),
        name6: this.$t("nav2-1-table-5-name"),
        name7: this.$t("nav2-1-table-6-name"),
        name8: this.$t("nav2-1-table-7-name"),
        name9: this.$t("nav2-1-table-8-name"),
        name10: this.$t("nav2-1-table-9-name"),
        name11: this.$t("nav2-1-table-10-name"),
        name12: this.$t("nav2-1-table-11-name"),
      };
    },
    kczyList() {
      return [
        {
          name: this.$t("nav2-2-product-1-name"),
          img: require("../assets/tjk.png"),
        },
        {
          name: this.$t("nav2-2-product-2-name"),
          img: require("../assets/tk.png"),
        },
        {
          name: this.$t("nav2-2-product-3-name"),
          img: require("../assets/lk.png"),
        },
        {
          name: this.$t("nav2-2-product-4-name"),
          img: require("../assets/hys.png"),
        },
        {
          name: this.$t("nav2-2-product-5-name"),
          img: require("../assets/ltk.png"),
        },
        {
          name: this.$t("nav2-2-product-6-name"),
          img: require("../assets/leikuo.png"),
        },
      ];
    },
    productList() {
      return [
        {
          name: this.$t("nav2-1-product-1-name"),
          img: require("../assets/gfxtbf.jpg"),
          type: "1",
          describe1: this.$t("nav2-1-product-1-describe1"),
          describe2: this.$t("nav2-1-product-1-describe2"),
        },
        {
          name: this.$t("nav2-1-product-2-name"),
          img: require("../assets/tltbf.jpg"),
          type: "1",
          describe1: this.$t("nav2-1-product-2-describe1"),
          describe2: this.$t("nav2-1-product-2-describe2"),
        },
        {
          name: this.$t("nav2-1-product-3-name"),
          img: require("../assets/zztbf.jpg"),
          type: "1",
          describe1: this.$t("nav2-1-product-3-describe1"),
          describe2: this.$t("nav2-1-product-3-describe2"),
        },
        {
          name: this.$t("nav2-1-product-4-name"),
          img: require("../assets/sltbf.jpg"),
          type: "1",
          describe1: this.$t("nav2-1-product-4-describe1"),
          describe2: this.$t("nav2-1-product-4-describe2"),
        },
        {
          name: this.$t("nav2-1-product-5-name"),
          img: require("../assets/smltbf.jpg"),
          type: "1",
          describe1: this.$t("nav2-1-product-5-describe1"),
          describe2: this.$t("nav2-1-product-5-describe2"),
        },
      ];
    },
    tableData2() {
      return [
        { name: this.$t("nav2-3-index-1"), value: 99.5, type: "≥" },
        {
          name: this.$t("nav2-3-index-2"),
          value: 12,
          type: " ≤",
        },
        { name: "S,ppm", value: 100, type: " ≤" },
        { name: "Fe,ppm", value: 35, type: " ≤" },
        { name: "P2O5,%", value: 0.05, type: "≤" },
        { name: "D50,um", value: 1.2, type: "≤" },
        { name: "Na,ppm", value: 50, type: "≤" },
        {
          name: "K,ppm",
          value: "50",
          type: "≤",
        },
        { name: this.$t("nav2-3-index-3"), value: 0.5, type: "≤" },
        { name: this.$t("nav2-3-index-4"), value: "40-80", type: " " },
        { name: this.$t("nav2-3-index-5"), value: 0.1, type: "≤" },
      ];
    },
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  methods: {
    anvChange(type) {
      if (type == "zh") {
        this.$i18n.locale = "zh";
        document.title = "浙江华钛化工有限公司";
      } else if (type == "en") {
        this.$i18n.locale = "en";
        document.title = "ZHEJIANG HUA TAI CHEMICALS LIMITED";
      } else {
        // 阿拉伯文
        this.$i18n.locale = "arab";
        document.title = "تقع شركة ZHEJIANG HUA TAI CHEMICALS LIMITED";
      }
    },
    changeLange() {
      this.$i18n.locale = this.lange;
    },
    windowScroll() {
      this.top = window.scrollY;
    },
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动效果
      });
    },
    dialogInfo(o, index) {
      this.dTitle = o.name;
      this.dContent.describe1 = o.describe1;
      this.dContent.describe2 = o.describe2;
      switch (index) {
        case 0:
          this.tableData = [
            { name: this.tableName.name1, value: 92, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97.5, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.0 - 9.0",
              type: "",
            },
            { name: this.tableName.name10, value: 19.5, type: "≤" },
            { name: this.tableName.name11, value: 80, type: "≥" },
            { name: this.tableName.name12, value: 97.0, type: "≥" },
          ];
          break;
        case 1:
          this.tableData = [
            { name: this.tableName.name1, value: 92, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97.5, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.0 - 9.0",
              type: "",
            },
            { name: this.tableName.name10, value: 22, type: "≤" },
            { name: this.tableName.name11, value: 60, type: "≥" },
            { name: this.tableName.name12, value: 97.5, type: "≥" },
          ];
          break;
        case 2:
          this.tableData = [
            { name: this.tableName.name1, value: 90, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.0 - 9.0",
              type: "",
            },
            { name: this.tableName.name10, value: 25, type: "≤" },
            { name: this.tableName.name11, value: 10, type: "≥" },
            { name: this.tableName.name12, value: 97.5, type: "≥" },
          ];
          break;
        case 3:
          this.tableData = [
            { name: this.tableName.name1, value: 93, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97.5, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.5 - 8.5",
              type: "",
            },
            { name: this.tableName.name10, value: 20, type: "≤" },
            { name: this.tableName.name11, value: 80, type: "≥" },
            { name: this.tableName.name12, value: 98.0, type: "≥" },
          ];
          break;
        case 4:
          this.tableData = [
            { name: this.tableName.name1, value: 96.5, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 98, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.5 - 8.5",
              type: "",
            },
            { name: this.tableName.name10, value: 18, type: "≤" },
            { name: this.tableName.name11, value: 80, type: "≥" },
            { name: this.tableName.name12, value: 97, type: "≥" },
          ];
          break;
        // case 5:
        //   this.tableData = [
        //     { name: this.tableName.name1, value: 96.5, type: "≥" },
        //     {
        //       name: this.tableName.name2,
        //       value: this.tableName.name3,
        //       type: "",
        //     },
        //     { name: this.tableName.name4, value: 0.5, type: " ≤" },
        //     { name: this.tableName.name5, value: 0.5, type: " ≤" },
        //     { name: this.tableName.name6, value: 0.02, type: "≤" },
        //     { name: this.tableName.name7, value: 98, type: "≥" },
        //     { name: this.tableName.name8, value: 100, type: "≥" },
        //     {
        //       name: this.tableName.name9,
        //       value: "6.0 - 8.5",
        //       type: "",
        //     },
        //     { name: this.tableName.name10, value: 18, type: "≤" },
        //     { name: this.tableName.name11, value: 80, type: "≥" },
        //     { name: this.tableName.name12, value: 97, type: "≥" },
        //   ];
        //   break;

        default:
          break;
      }
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.top {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  width: 100%;
}
.top-nav {
  padding-left: calc((100vw - 1182px) / 2);
  padding-right: calc((100vw - 1182px) / 2);
}
.home {
  padding-top: 100px;
}
.w80 {
  width: 1182px;
  margin: 0 auto;
}
.logo {
  height: 60px;
  width: auto;
  padding: 20px 0;
}
.lange {
  margin-left: 26px;
}
h1,
h3 {
  margin: 10px 0;
  text-align: center;
}
.main-img {
  width: 100%;
  height: 600px;
}
.main-content-title {
  border-bottom: 1px dashed #9b9c9c;
  position: relative;
  margin-top: 70px;
}
.main-content-title-one {
  border-bottom: 6px solid #29b0d7;
  display: inline-block;
  position: absolute;
  bottom: -3px;
  font-size: 20px;
  color: #29b0d7;
  padding-bottom: 10px;
  font-weight: 700;
}
.main-content-title-two {
  font-size: 16px;
  color: #29b0d7;
  display: inline-block;
  position: absolute;
  bottom: -3px;
  left: 138px;
  padding-bottom: 18px;
}
.main-content-title-two-en {
  font-size: 16px;
  color: #29b0d7;
  display: inline-block;
  position: absolute;
  bottom: -3px;
  left: 138px;
  padding-bottom: 18px;
  left: 243px;
  bottom: -6px;
}
.main-content {
  color: #333;
  line-height: 2;
  font-size: 18px;
  padding-top: 10px;
}
::v-deep .el-carousel__container {
  height: 600px;
}
.image {
  height: 400px;
  width: 400px;
  cursor: pointer;
}
.card-name {
  color: #29b0d7;
  font-weight: 700;
}
.describe {
  color: #666;
  line-height: 1.8;
  margin-top: 15px;
  min-height: 55px;
  min-height: 80px;
}
.bottom {
  width: 100%;
  padding: 20px 0;
  background-color: #474747;
  color: #fff;
  text-align: center;
}
.goTop {
  position: fixed;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #0552a0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 5;
  right: 50px;
  bottom: 120px;
}
.arabGoTop {
  right: none;
  left: 50px;
}
/*nav*/
#nav {
  float: right;
}
#nav li {
  float: left;
  font-size: 16px;
  line-height: 100px;
  position: relative;
}
#nav li .go {
  display: block;
  color: #010101;
  font-family: "微软雅黑";
  padding: 0 18px;
  cursor: pointer;
  font-size: 22px;
  /* border-radius: 3px; */
}
#nav li em {
  display: none;
}
#nav li > .go:hover,
#nav li.cur > .go {
  /* background-color: #29b0d7; */
  color: #55aaff;
}
#nav .drop {
  padding: 20px 18px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 6;
  width: fit-content;
  display: none;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #010101;
  font-size: 15px;
  border-radius: 5px;
  border: solid 1px #d7d7d7;
}
#nav .drop span {
  margin-top: 5px;
  font-size: 15px;
  color: #55aaff;
  line-height: 1.25em;
  display: block;
  margin-bottom: 5px;
}
#nav li:hover .drop {
  display: block;
  color: #55aaff;
}
#nav dt {
  display: none;
}
#nav dd {
  line-height: 1.75em;
  font-size: 14px;
}
#nav dd .go {
  padding: 3px 0;
  color: #666;
  display: inline-block;
  /* float: left; */
  margin-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#nav dd .go:hover {
  color: #55aaff;
}

/*language*/
.language {
  float: right;
  line-height: 38px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 30px 33px 30px 15px;
  color: #bababa;
}
.language span {
  color: #bababa;
  padding: 0 3px;
  cursor: pointer;
}
.language span:hover,
.language span.cur {
  color: #676767;
}
.lxwm {
  position: absolute;
  top: 215px;
  left: 0;
  font-size: 38px;
  color: #fff;
  text-align: center;
  width: 100%;
  line-height: 2;
  font-weight: 700;
}
.lxwm-p {
  margin-top: 30px;
  font-size: 20px;
  line-height: 2.5;
}
.lxwm-i {
  margin-right: 10px;
  font-size: 24px;
}
.lxwm-s {
  font-weight: 700;
}
.lxwm-i-p {
  color: #595959;
  width: 100%;
}
.lxwm-t {
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: 3px;
}
.card-title {
  font-weight: 700;
}
.kczy-title {
  position: absolute;
  top: 205px;
  left: 0;
  font-size: 28px;
  color: #29b0d7;
  text-align: center;
  width: 100%;
  line-height: 2;
  font-weight: 700;
}
.dialog-content {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
}

::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #b8cce4;
  color: black;
}
::v-deep .el-table th.el-table__cell {
  background: #4f81bd;
  color: black;
  font-weight: 700;
}
::v-deep table {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  color: black;
}
::v-deep .el-table {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}
::v-deep .el-table td,
::v-deep .el-table th {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}
.drawer-content {
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}
::v-deep .el-drawer__header {
  font-weight: 700;
  font-size: 24px;
  color: black;
}
</style>
